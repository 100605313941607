import React, { useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
} from "antd";
import { useResourcePost } from "../../Hooks/post/usePost.query";
import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import SendContract from "./sendContract";
import NewClient from "./newClient";
import locale from "antd/es/date-picker/locale/pt_BR";

const { Search } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 19 },
};

const CreateContract = () => {
  const [form] = Form.useForm();

  const [openFormNewClient, setOpenFormNewClient] = useState(false);
  const [openFormExistClient, setOpenFormExistClient] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const [dataContract, setDataContract] = useState({});
  const [contractDataSend, setContractDataSend] = useState(false);
  const [dataFormContract, setDataFormContract] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entryDate, setEntryDate] = useState(new Date());
  const [cpfValue, setCpfValue] = useState("")

  const { data: dataSelectPlan, status: statusPlan } = useQuery(["plans"], () =>
    api
      .get(`${process.env.REACT_APP_API}/v1/plan`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      })
      .then((res) => {
        return res.data.data;
      })
  );

  const { mutateAsync, isPending } = useResourcePost(
    "cpf",
    "profile",
    "existing-client",
    (data) => {
      console.log("success", data);
      setOpenFormNewClient(false);
      setOpenFormExistClient(true);
      setDataUser(data);
    },
    (err) => {
      console.log("err))", err);
      setOpenFormExistClient(false);
      setOpenFormNewClient(true);
      setIsModalOpen(true);
    }
  );

  const onSearch = (value, _e, info) => {
    setDataContract({});
    let dataForm = {
      document: value,
    };
    setCpfValue(value)
    mutateAsync(dataForm);
  };

  const onFinish = (e) => {
    setDataContract({
      newPlan: e.plan,
      newApport: e.valor,
      dateApport: entryDate,
    });
    setContractDataSend(!contractDataSend);
    setDataFormContract({
      ...dataUser,
      newPlan: e.plan,
      newApport: e.valor,
      dateApport: entryDate,
    });
  };

  const handleSendContract = () => {
    setIsModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeDatePicker = (date, dateString) => {
    console.log(dateString);
    setEntryDate(dateString);
  };

  return (
    <Card>
      <Search
        placeholder="CPF"
        allowClear
        onSearch={onSearch}
        style={{
          width: 304,
          height: 50,
        }}
        loading={isPending}
        height={50}
        enterButton={
          <Button type="primary" style={{ height: 50, width: 100 }}>
            Buscar
          </Button>
        }
      />
      <Divider />

      {openFormExistClient && (
        <>
          <Form {...layout} form={form} name="create-user" onFinish={onFinish}>
            <Form.Item name="plan" label="Plano">
              <Select>
                {statusPlan === "loading" && (
                  <Option key="loading" disabled>
                    Loading...
                  </Option>
                )}
                {statusPlan === "success" &&
                  dataSelectPlan.map((item, index) => (
                    <Option key={index + 1} value={item.id}>
                      {item.plan}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="valor"
              label="Valor de aporte"
              rules={[{ required: true }]}
            >
              <InputNumber
                style={{
                  width: "100%",
                }}
                defaultValue={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
            <Form.Item
              name="data"
              label="Data de inicio do contrato"
              rules={[{ required: true }]}
            >
              {/* <Input /> */}
              <DatePicker
                locale={locale}
                onChange={onChangeDatePicker}
                picker=""
              />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                // loading={!!sendingForm}
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
              >
                Próximo
              </Button>
            </Form.Item>
          </Form>

          {contractDataSend && (
            <>
              <h1>Dados do cliente para criar contrato</h1>
              <p>Nome: {dataUser.name}</p>
              <p>Cpf: {dataUser.document}</p>
              <p>Email: {dataUser.email}</p>
              <p>Telefone: {dataUser.phone}</p>
              <p>Plano: {dataContract.newPlan}</p>
              <p>Valor de aporte: {dataContract.newApport}</p>
              <p>Inicio do contrato: {dataContract.dateApport}</p>
              <Button
                // loading={!!sendingForm}
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
                onClick={handleSendContract}
              >
                Enviar
              </Button>
            </>
          )}
        </>
      )}

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={false}
      >
        {openFormNewClient && <NewClient cpf={cpfValue} />}
        {openFormExistClient && <SendContract data={dataFormContract} />}
      </Modal>
    </Card>
  );
};

export default CreateContract;
