import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  useContractRequest,
  useContractZapSignRequest,
  useReadContractUpdate,
} from "../../Hooks/contracts/useContracts.query";
import {
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Flex,
  Image,
  Input,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import {
  formatColorZapSign,
  formatStatusZapSign,
} from "../../helpers/formatStatus";
import { formatDate } from "../../helpers/dateHelper";
import {
  validateCep,
  validateCnh,
  validateCpf,
  validateEmail,
  validatePhone,
  validateRg,
} from "../../helpers/validateInputsPersonal";
import {
  CheckCircleOutlined,
  SendOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ClipboardCopy from "../../helpers/copyToClipboard";

import { Typography } from "antd";
import { formatCurrency } from "../../helpers/moneyFormat";
import itemsDescriptionsData from "../../helpers/itemsDescriptionsData";
import { useMutation } from "@tanstack/react-query";
import api from "../../services/api";
const { Title, Text } = Typography;

const DetailsContract = () => {
  const { id, params } = useParams();

  const { data: contract, isLoading: loadingContract } = useContractRequest(id);


  const { mutate } = useMutation(useReadContractUpdate, {
    onSuccess: (data) => {
      api.success({
        message: "Sucesso",
        description: "Alterado com sucesso.",
      });
    },
  });

  // useEffect(() => {
  //   sendIsReadContract();
  // }, [sendIsReadContract]);

  const sendIsReadContract = () => {
    mutate(id);
  };

  const arDataProfile = [
    itemsDescriptionsData("Nome completo", contract?.name),
    itemsDescriptionsData(
      "Estado civil",
      contract?.marital_status,
      "VERIFICAR ESTADO CIVIL"
    ),
    itemsDescriptionsData(
      "Profissão",
      contract?.profession,
      "VERIFICAR PROFISSÃO"
    ),
    itemsDescriptionsData("Telefone", contract?.phone, "VERIFICAR TELEFONE"),
    itemsDescriptionsData("Whatsapp", contract?.whatsapp, "VERIFICAR WHATSAPP"),
    itemsDescriptionsData("Email", contract?.email, "VERIFICAR EMAIL"),
  ];

  const arDataDoc = [
    itemsDescriptionsData("RG", contract?.number_rg, "VERIFICAR RG"),
    itemsDescriptionsData(
      "Expedição",
      contract?.expedition_rg,
      "VERIFICAR EXPEDIÇÃO"
    ),
    itemsDescriptionsData("CPF", contract?.number_cpf, "VERIFICAR CPF"),
    itemsDescriptionsData("CNH", contract?.number_cnh, "VERIFICAR CNH"),
  ];

  const adDataAddress = [
    itemsDescriptionsData("Cep", contract?.postal_code, "VERIFICAR CEP"),
    itemsDescriptionsData("Rua", contract?.street, "VERIFICAR RUA"),
    itemsDescriptionsData("Numero", contract?.number, "VERIFICAR NUMERO"),
    itemsDescriptionsData("Cidade", contract?.city, "VERIFICAR CIDADE"),
    itemsDescriptionsData("Bairro", contract?.neighborhood, "VERIFICAR BAIRRO"),
    itemsDescriptionsData("Estado", contract?.state, "VERIFICAR ESTADO"),
  ];

  const arDataContract = [
    itemsDescriptionsData(
      "Valor Investimento",
      contract?.amount_contract <= 0
        ? null
        : formatCurrency(contract?.amount_contract, "BRL"),
      "VERIFICAR O VALOR"
    ),
    itemsDescriptionsData("Plano", contract?.plan, "VERIFICAR PLANO"),
    itemsDescriptionsData(
      "Tempo de contrato (meses)",
      contract?.contract_time,
      "VERIFICAR PLANO"
    ),
    itemsDescriptionsData(
      "OBSERVAÇÕES",
      contract?.observations ? contract?.observations : "sem observação"
    ),
  ];

  const {
    data: zpSing,
    isLoading: loadingZapSign,
    isFetching,
  } = useContractZapSignRequest(contract?.contract?.token_ZPS);

  const contractArr = contract?.contract;
  const signersArr = zpSing?.signers;

  console.log(contract);


  const handleResendMail = () => {};

  if (loadingContract) {
    return (
      <div
        className="layout-content"
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (zpSing?.detail == 'Não encontrado.') {
    return (
      <Card>
        <p>O contrato não foi encontrado no sistema.</p>
        <p>Tente enviar no menu "Enviar" ao lado.</p>
        <p>Caso nao funcione por favor entre em contato com o administrador.</p>
      </Card>
    )
  }

  return (
    <>
      <Col md={24}>
        <Card loading={!!isFetching}>
          <p>Contrato ID: {contract?.contract?.contract_number}</p>
          {/* verificação de dados */}
          {!contractArr?.status_ZPS ? (
            <>
              <p>O contrato ainda nao foi enviado para o cliente</p>

              <Divider />
              <Descriptions bordered column={1} items={arDataProfile} />
              <br />
              <Row justify="center" align="top">
                <Col>
                  <p>RG</p>
                  <Image
                    width={200}
                    src={contract?.image_rg}
                    alt={contract?.number_rg}
                  />
                </Col>
                <Col>
                  <p>CPF</p>
                  <Image
                    width={200}
                    src={contract?.image_cpf}
                    alt={contract?.number_cpf}
                  />
                </Col>
                <Col>
                  <p>CNH</p>
                  <Image
                    width={200}
                    src={contract?.image_cnh}
                    alt={contract?.number_cnh}
                  />
                </Col>
              </Row>

              <Descriptions bordered column={1} items={arDataDoc} />
              <br />
              <Descriptions bordered column={1} items={adDataAddress} />
              <br />
              <Descriptions bordered column={1} items={arDataContract} />
            </>
          ) : (
            <>
              <Title level={2}>{zpSing?.name}</Title>
              <Tag
                icon={
                  zpSing?.status === "pending" ? (
                    <SyncOutlined spin />
                  ) : (
                    <CheckCircleOutlined />
                  )
                }
                color={zpSing?.status === "pending" ? "orange" : "green"}
                style={{ marginBottom: 10, fontSize: 18 }}
              >
                {zpSing?.status === "pending" ? "EM CURSO" : "ASSINADO"}
              </Tag>
              <Flex justify="space-between">
                <Text>
                  Criado em <Text strong>{formatDate(zpSing?.created_at)}</Text>{" "}
                  - por <Text strong>{zpSing?.created_by?.email}</Text>
                </Text>

                <Text>
                  Ultima atualização:{" "}
                  <Text strong>{formatDate(zpSing?.last_update_at)}</Text>
                </Text>
              </Flex>

              <Divider />
              <Flex align="" justify="space-between">
                {zpSing?.signed_file && (
                  <Button type="primary">
                    <a href={zpSing?.signed_file} target="_blank">
                      Ver Documento
                    </a>
                  </Button>
                )}
                <Button type="text">
                  <a href={zpSing?.original_file} target="_blank">
                    Documento Original
                  </a>
                </Button>
              </Flex>

              <br />
              <br />

              {signersArr?.map((signer) => (
                <>
                  <Card
                    bordered={false}
                    style={{
                      marginBottom: 20,
                    }}
                    styles={{
                      body: {
                        borderRadius: "10px",
                        padding: 0,
                      },
                    }}
                  >
                    <Card
                      key={signer.id}
                      bordered={false}
                      style={{
                        borderRadius: "10px",
                        height: "auto",
                        marginBottom: 5,
                      }}
                      styles={{
                        body: {
                          borderRadius: "10px",
                          padding: 0,
                          // height: "85px"
                        },
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: 16,
                        }}
                      >
                        <div
                          style={{
                            width: "25px",
                            background: formatColorZapSign(signer.status),
                            // height: "85px",
                            borderRadius: "10px 0 0 10px",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          {formatStatusZapSign(signer.status)}
                        </div>

                        <div
                          style={{
                            flex: 3,
                            display: "flex",
                            flexDirection: "column",
                            padding: "5px 0 5px 0",
                          }}
                        >
                          <Title
                            level={4}
                            style={{
                              padding: 0,
                              marginTop: 5,
                              marginBottom: 5,
                            }}
                          >
                            {signer.name} <br />
                          </Title>
                          {signer.status === "signed" && (
                            <div>
                              Telefone: {signer.phone_number} <br />
                              Email: {signer.email}
                            </div>
                          )}

                          {signer.status !== "signed" && (
                            <div>
                              {signer.times_viewed === 0 && (
                                <Text strong>
                                  <strong>Não visualizou ainda</strong>
                                </Text>
                              )}
                              {signer.times_viewed > 0 && (
                                <>
                                  <Text>
                                    Visualizou o documento
                                    {signer.times_viewed === 1
                                      ? `${signer.times_viewed} vez`
                                      : `${signer.times_viewed} vezes`}
                                    <br />
                                    <Text strong>
                                      Última visualização{" "}
                                      {formatDate(signer?.last_view_at)}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            justifyContent: "flex-end",
                            marginRight: 15,
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              // width: "25px",
                              alignContent: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            {signer.status === "signed" && (
                              <Text strong style={{ textAlign: "right" }}>
                                Assinou o documento em
                                <br />
                                <Text>{formatDate(signer.signed_at)}</Text>
                              </Text>
                            )}
                            {/* {signer.status !== "signed" && (
                              <Tooltip placement="top" title={"Enviar e-mail"}>
                                <Button type="link" onClick={handleResendMail}>
                                  <SendOutlined style={{ fontSize: 21 }} />
                                </Button>
                              </Tooltip>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </Card>
                    <ClipboardCopy copyText={signer?.sign_url} />
                  </Card>
                  <Divider />
                </>
              ))}
            </>
          )}
        </Card>
      </Col>
    </>
  );
};

export default DetailsContract;
