import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../services/api";

const postResource = async (controller, action, values) => {
  try {
    const data = await api.post(
      `${process.env.REACT_APP_API}/v1/${controller}/${action}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(`Error on ${controller}/${action}`, error.response);
    return error;
  }
};

export const useResourcePost = (
  queries,
  controller,
  action,
  onSuccessCallback,
  onErrorCallback
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => postResource(controller, action, data),
    onSuccess: ({ data }) => {
      if (data.status === 400 || data.status === 500) {
        console.log("?", data);
        if (onErrorCallback) {
          onErrorCallback(data.message);
        }
        return;
      }

      queryClient.invalidateQueries({ queryKey: [queries] });

      if (onSuccessCallback) {
        onSuccessCallback(data.data);
      }
    },
    onError: ({ error }) => {
      onErrorCallback(error.message);
    },
  });
};
