import React from "react";
import { Form, Input, Button, Upload, Select, InputNumber } from "antd";
import { IMaskInput } from "react-imask";
import { UploadOutlined } from "@ant-design/icons";
import DefaultUpload from "../uploads/DefaultUpload";
import generateUuid from "../../helpers/generateUuid";
import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";

const { Option } = Select;

const maskMap = {
  cpf: "000.000.000-00",
  cnpj: "00.000.000/0000-00",
  telefone: "(00) 00000-0000",
  celular: "(00) 00000-0000",
  cep: "00.000-000",
};

const DynamicForm = ({
  fields,
  onSubmit,
  callbackResponse,
  setCallbackResponse,
}) => {
  const [form] = Form.useForm();

  const { data: dataSelectPlan, status: statusPlan } = useQuery(["plans"], () =>
    api
      .get(`${process.env.REACT_APP_API}/v1/plan`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      })
      .then((res) => {
        return res.data.data;
      })
  );

  const fetchAddress = async (cep) => {
    try {
      const response = await fetch(
        `https://brasilapi.com.br/api/cep/v2/${cep}`
      );
      const data = await response.json();
      form.setFieldsValue({
        street: data.street,
        city: data.city,
        state: data.state,
        neighborhood: data.neighborhood,
      });
    } catch (error) {
      console.error("Erro ao buscar o CEP", error);
    }
  };

  const handleUploadCallback = (fieldName, url) => {
    setCallbackResponse((prev) => ({
      ...prev,
      [fieldName]: url,
    }));
  };

  const handleFinish = (values) => {
    onSubmit({ ...values, ...callbackResponse });
  };

  const renderInput = (field) => {
    if (field.mask && maskMap[field.mask]) {
      return (
        <IMaskInput
          mask={maskMap[field.mask]}
          definitions={{ "#": /[0-9]/ }}
          unmask={true}
          inputRef={(el) =>
            el && form.setFieldsValue({ [field.name]: el.value })
          }
          className="ant-input"
          onBlur={(e) =>
            field.name === "postal_code" && fetchAddress(e.target.value)
          }
          width={"100%"}
        />
      );
    }
    if (field.type === "amount") {
      return (
        <InputNumber
          style={{
            width: "100%",
          }}
          defaultValue={0}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
        />
      );
    }
    if (field.type === "upload") {
      return (
        <DefaultUpload
          title={generateUuid()}
          label={`Enviar arquivo`}
          folder={`documentation_activation_account/materials-managers/`}
          controller="material"
          action="uploadmaterials"
          callback={(url) => handleUploadCallback(field.name, url)}
          {...field.props}
        />
      );
    }
    if (field.type === "selectplan") {
      return (
        <Select>
          {statusPlan === "loading" && (
            <Option key="loading" disabled>
              Loading...
            </Option>
          )}
          {statusPlan === "success" &&
            dataSelectPlan.map((item, index) => (
              <Option key={index + 1} value={item.id}>
                {item.plan}
              </Option>
            ))}
        </Select>
      );
    }
    return <Input type={field.type} placeholder={field.placeholder} />;
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      {fields.map((field) => (
        <Form.Item
          key={field.name}
          label={field.label}
          name={field.name}
          rules={[
            {
              required: field.required,
              message: `Por favor, insira ${field.label}`,
            },
          ]}
        >
          {renderInput(field)}
        </Form.Item>
      ))}
      <Button type="primary" htmlType="submit">
        Enviar
      </Button>
    </Form>
  );
};

export default DynamicForm;
