import React, { useState } from "react";
import { formatCurrency } from "../../helpers/moneyFormat";
import { Button, Form, Input, Modal, notification } from "antd";
import { useResourcePost } from "../../Hooks/post/usePost.query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useProfileContractUpdate } from "../../Hooks/profile/useProfile.query";
import { formatarData } from "../../helpers/dateHelper copy";

const date = new Date();

const SendContract = ({ data }) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification()
  const queryClient = useQueryClient()
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const { isLoading, mutate } = useMutation(useProfileContractUpdate, {
    onSuccess: (data) => {
      console.log(data)
      api.open({
        message: data?.data.message,
      })
    //   if (data.status === 404) {
    //     api.error({
    //       message: 'Error',
    //       description: data.error,
    //     })
    //     return
    //   }
    //   form.resetFields()
      queryClient.invalidateQueries('profile')
    //   api.success({
    //     message: 'Sucesso',
    //     description: 'Dados Salvo com sucesso',
    //   })
    //   navigate(`/investidor/perfil/${id}`)
    },
    // onError: (err) => {
    //   api.error({
    //     message: 'Error',
    //     description: err.error,
    //   })
    // },
  })

  const { mutateAsync, isPending } = useResourcePost(
    "contrato",
    "contracts",
    "create-contract",
    (data) => {
      console.log("success: ", data);
      api.open({
        message: data?.message,
      })
    },
    (err) => {
      console.log("err: ", err);
    }
  );

  const handleClickSendZapsign = () => {
    mutateAsync(data);
  };

  const handleEditZapsign = () => {
    setIsModalEditOpen(true)
  }


  const handleFinish = (values) => {
    mutate(values)
    // onSubmit({ ...values, ...callbackResponse });
  };

  return (
    <>
      {contextHolder}
      <div
        style={{
          maxWidth: "210mm",
          overflow: "hidden",
          margin: "0 auto",
          padding: "25px",
        }}
        className="printable-content"
      >
        <div>
          <p className="c12">
            <span
              style={{
                overflow: "hidden",
                display: "flex",
                margin: "0 auto ",
                border: "0.00px solid #000000",
                transform: "rotate(0.00rad) translateZ(0px)",
                WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                width: "150px",
                height: "150px",
                marginBottom: "15px",
              }}
            >
              <img
                alt=""
                src="https://ik.imagekit.io/qqrtx9mgqo/assets/rtxholding_preto.png?updatedAt=1715882380483"
                style={{
                  width: "150px",
                  height: "150px",
                  marginLeft: "0.00px",
                  marginTop: "0.00px",
                  transform: "rotate(0.00rad) translateZ(0px)",
                  WebkitTransform: "rotate(0.00rad) translateZ(0px)",
                  justifyContent: "center",
                }}
                title
              />
            </span>
          </p>
        </div>

        <p className="c2">
          <span className="c5">INSTRUMENTO PARTICULAR DE - </span>
          <span className="c9 c5">PRIVATE EQUITY –</span>
          <span className="c10 c5">&nbsp;COM OPÇÃO DE CONVERSÃO EM COTAS</span>
        </p>
        <p className="c2 c11">
          <span className="c8" />
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c12">
          <span className="c9">INVESTIDOR:</span>
          <span className="c9 c5">&nbsp;</span>
          <span className="c8">
            <b>{data?.name}</b>, <b>{data?.marital_status || "VERIFICAR"}</b>, {" "}
            <b>{data?.profession || "VERIFICAR"}</b>, portador da CI RG sob nº {" "}
            <b>{data?.documents?.number_rg || data?.number_rg}</b>
            /SSP-PR, inscrito no CPF/MF sob nº
            <b> {data?.documents?.number_cpf || data?.number_rg}</b>, residente
            no endereço à Rua &nbsp;
            <b>{data?.address?.street || data?.street}</b>, &nbsp; nº{" "}
            <b>{data?.address?.number || data?.number}</b>, Bairro&nbsp;
            <b>{data?.address?.neighborhood || data?.neighborhood}</b>, CEP{" "}
            <b>{data?.address?.postal_code || data?.postal_code}</b>, na cidade
            de
            <b>{data?.address?.city || data?.city}</b>, Estado &nbsp;{" "}
            <b>{data?.address?.state || data?.state}</b> , ora denominado
            investidora;
          </span>
        </p>
        <p className="c1">
          <span className="c0" />
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c3">
          <span className="c9">SOCIEDADE OUTORGADA: </span>
          <span className="c9 c5">RTX PARTICIPAÇÕES HOLDING LTDA</span>
          <span className="c9">
            , pessoa jurídica de direito privado, inscrita no CNPJ sob o n°
            55.018.355/0001-08, neste ato representada por seu sócio
            administrador RAFAEL TOSTES DOS SANTOS, estabelecida na Avenida
            Carneiro Leão, nº 563, Sala 1214, Pavimento 12, CEP 87.014-042, Zona
            4, Maringá-PR.
          </span>
        </p>
        <p className="c1">
          <div
            style={{
              width: "100%",
              height: "25px",
              background: "#ccc",
              margin: "3px 0",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "25px",
              background: "#ccc",
              margin: "3px 0",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "25px",
              background: "#ccc",
              margin: "3px 0",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "25px",
              background: "#ccc",
              margin: "3px 0",
            }}
          />
        </p>
        <p className="c3">
          <span className="c9">Paragrafo 1°: </span>
          <span className="c0">
            O capital investido será destinado a gestão da atividade, e o
            investidor não possui gerencia sobre o mesmo, sendo que em razão
            desta condição não possui direito a voto nem participação na
            administração direta da sociedade investida.
          </span>
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c3">
          <span className="c9">Paragrafo 2°: </span>
          <span className="c0">
            O capital investido neste ato está representado pela quantia de{" "}
            <b>
              {formatCurrency(data?.newApport || data.contract_amount, "BRL")}
            </b>{" "}
            valor este devidamente depositado na conta:
          </span>
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c1">
          <div
            style={{
              width: "100%",
              height: "25px",
              background: "#ccc",
              margin: "3px 0",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "25px",
              background: "#ccc",
              margin: "3px 0",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "25px",
              background: "#ccc",
              margin: "3px 0",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "25px",
              background: "#ccc",
              margin: "3px 0",
            }}
          />
        </p>
        <p className="c3">
          <span className="c9">CLÁUSULA 10°: </span>
          <span className="c0">
            Elegem as partes a comarca de Maringá Estado do Paraná, para dirimir
            quaisquer dúvidas e conflitos originários do presente instrumento.
          </span>
        </p>
        <p className="c1">
          <span className="c8" />
        </p>
        <p className="c3">
          <span className="c0">
            Maringá, {formatarData(data?.dateApport)}.
          </span>
        </p>
      </div>
      <Button onClick={handleClickSendZapsign}>
        Enviar para cliente via ZapSign
      </Button>


      <Modal title="Editar cadastro" open={isModalEditOpen} onCancel={() => setIsModalEditOpen(false)} footer={false}>
        <Form form={form} layout="vertical" onFinish={handleFinish}
          fields={[
            { name: ['id'], value: data?.user_id },
            { name: ['phone'], value: data?.phone },
            { name: ['whatsapp'], value: data?.whatsapp },
            { name: ['marital_status'], value: data?.marital_status },
            { name: ['profession'], value: data?.profession },
            { name: ['nationality'], value: data?.nationality },
            { name: ['postal_code'], value: data?.address?.postal_code },
            { name: ['street'], value: data?.address?.street },
            { name: ['number'], value: data?.address?.number },
            { name: ['city'], value: data?.address?.city },
            { name: ['state'], value: data?.address?.state },
            { name: ['neighborhood'], value: data?.address?.neighborhood },
            { name: ['number_rg'], value: data?.document?.number_rg },
            { name: ['number_cpf'], value: data?.document?.number_cpf },
          ]}
        >
          <Form.Item
            name="id"
            label="ID"
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="number_rg"
            label="RG"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="number_cpf"
            label="CPF"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Telefone"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="whatsapp"
            label="Whatsapp"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="marital_status"
            label="Estado Civil"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="profession"
            label="Profissão"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="nationality"
            label="Nacionalidade"
          >
            <Input />
          </Form.Item>


          <Form.Item
            name="postal_code"
            label="CEP"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="street"
            label="Rua"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="number"
            label="Numero"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="city"
            label="Cidade"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="state"
            label="Estado"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="neighborhood"
            label="Bairro"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="complement"
            label="Complemento"
          >
            <Input />
          </Form.Item>

          <Form.Item >
            <Button
              loading={!!isLoading}
              style={{ width: '100%', marginRight: 0 }}
              type="primary"
              htmlType="submit"
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal >
      <Button onClick={handleEditZapsign}>
        Editar
      </Button>


    </>
  );
};

export default SendContract;
