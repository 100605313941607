import { QueryClientProvider } from "@tanstack/react-query";
// import "antd/dist/antd.min.css";
import "./App.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { queryClient } from "./clientProvider";

import { Suspense } from "react";
import MainRouter from "./routes";
// beta
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Suspense fallback={<div>app loading</div>}>
          <MainRouter />
        </Suspense>
      </div>
    </QueryClientProvider>
  );
}

export default App;
