import { QueryClient } from '@tanstack/react-query'
import axios from 'axios'

const queryClient = new QueryClient({
  defaultOptions: {
    retry: 2,
    staleTime: 1000 * 30, // 30seconds
    cacheTime: 1000 * 30, //30 seconds
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 30, //30 seconds
    refetchIntervalInBackground: false,
    suspense: false
  }
})

axios.get('/')

export default queryClient
